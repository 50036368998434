import { useState, useEffect, useCallback } from 'react'
import { GridApi } from 'ag-grid-enterprise'
import { ZOOM_LIMITS } from '../constants'
import debounce from 'lodash/debounce'

export const useGridZoom = (gridApiRef: React.MutableRefObject<GridApi | null>) => {
  const [fontSize, setFontSize] = useState(`${ZOOM_LIMITS.FONT.DEFAULT}px`)
  const [rowHeight, setRowHeight] = useState<number>(ZOOM_LIMITS.ROW.DEFAULT)

  const debouncedGridUpdate = useCallback(
    debounce((api: GridApi) => {
      if (!api.isDestroyed()) {
        api.resetRowHeights()
        api.sizeColumnsToFit()
        api.refreshCells({ force: true })
      }
    }, 100),
    []
  )

  const updateZoom = useCallback((zoomFactor: number) => {
    setFontSize(prev => {
      const currentSize = parseFloat(prev)
      const newSize = Math.max(
        ZOOM_LIMITS.FONT.MIN,
        Math.min(ZOOM_LIMITS.FONT.MAX, currentSize * zoomFactor)
      )
      return `${newSize}px`
    })

    setRowHeight(prev => 
      Math.max(
        ZOOM_LIMITS.ROW.MIN,
        Math.min(ZOOM_LIMITS.ROW.MAX, Math.round(prev * zoomFactor))
      )
    )

    if (gridApiRef.current && !gridApiRef.current.isDestroyed()) {
      debouncedGridUpdate(gridApiRef.current)
    }
  }, [debouncedGridUpdate])

  const handleZoom = useCallback((e: WheelEvent) => {
    if ((e.ctrlKey || e.metaKey) && e.target instanceof Element) {
      // Check if the event target is within the grid
      const gridElement = document.querySelector('.ag-theme-alpine, .ag-theme-alpine-dark')
      if (!gridElement?.contains(e.target)) return

      e.preventDefault()
      const delta = e.deltaY > 0 ? -1 : 1
      const zoomFactor = 1 + (delta * 0.1) // Increased zoom factor for smoother zooming
      updateZoom(zoomFactor)
    }
  }, [updateZoom])

  useEffect(() => {
    document.addEventListener('wheel', handleZoom, { passive: false })
    
    return () => {
      document.removeEventListener('wheel', handleZoom)
      debouncedGridUpdate.cancel()
    }
  }, [handleZoom, debouncedGridUpdate])

  return { fontSize, rowHeight }
}
