import { CustomStatusPanelProps } from 'ag-grid-react';
import React from 'react';
import { Box, Button } from '@mui/material';

interface SoldPageStatusBarProps extends CustomStatusPanelProps {
  isSellPage?: boolean;
  onToggleSoldPage?: (checked: boolean) => void;
}

export default ({ api, isSellPage, onToggleSoldPage }: SoldPageStatusBarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        borderTop: '1px solid #e0e0e0',
        bgcolor: '#f5f5f5',
        height: '32px'
      }}
    >
      <Button
        onClick={() => onToggleSoldPage?.(false)}
        sx={{
          minWidth: '120px',
          textTransform: 'none',
          borderRadius: 0,
          borderRight: '1px solid #e0e0e0',
          borderTop: isSellPage ? 'none' : '2px solid #1976d2',
          bgcolor: isSellPage ? '#f5f5f5' : '#fff',
          '&:hover': {
            bgcolor: isSellPage ? '#eaeaea' : '#f8f8f8',
          }
        }}
      >
        Active Inventory
      </Button>
      <Button
        onClick={() => onToggleSoldPage?.(true)}
        sx={{
          minWidth: '120px',
          textTransform: 'none',
          borderRadius: 0,
          borderRight: '1px solid #e0e0e0',
          borderTop: isSellPage ? '2px solid #1976d2' : 'none',
          bgcolor: isSellPage ? '#fff' : '#f5f5f5',
          '&:hover': {
            bgcolor: isSellPage ? '#f8f8f8' : '#eaeaea',
          }
        }}
      >
        Sold Vehicles
      </Button>
    </Box>
  );
};