import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, Stack, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import FilterListIcon from '@mui/icons-material/FilterList';
import HistoryIcon from '@mui/icons-material/History';
import { useAuth } from 'contexts/AuthContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { collection, getFirestore, query, where, orderBy, limit, getDocs, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { useInventory } from 'hooks/useInventory';
import dayjs from 'dayjs';

const db = getFirestore();

interface HistoryEntry {
  id: string;
  documentId: string;
  timestamp: any;
  alphas?: Record<string, any>;
  deltas?: Record<string, any>;
  fullData?: Record<string, any>;
  modifiedBy: string;
  collection: string;
  type: 'update' | 'create' | 'delete';
  identifier: string;
  docId: string;
}

interface UndoLastActionProps {
  inventoryCollection: string;
}

export const UndoLastAction: React.FC<UndoLastActionProps> = ({ inventoryCollection }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { userInfo } = useAuth();
  const { updateDocument, deleteDocument, restoreDocument } = useInventory(inventoryCollection);
  const queryClient = useQueryClient();
  
  const [remainingActions, setRemainingActions] = useState(0);

  const [fieldChanges, setFieldChanges] = useState<Record<string, HistoryEntry[]>>({});
  const [showFieldRevertDialog, setShowFieldRevertDialog] = useState(false);
  const [selectedField, setSelectedField] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!userInfo?.email || !confirmOpen) return;

    const historyRef = collection(db, 'document_history');
    const q = query(
      historyRef,
      where('collection', '==', inventoryCollection),
      where('modifiedBy', '==', userInfo.email),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setRemainingActions(snapshot.size);
    });

    return () => unsubscribe();
  }, [userInfo?.email, inventoryCollection, confirmOpen]);

  useEffect(() => {
    if (!userInfo?.email || !showFieldRevertDialog) return;

    const historyRef = collection(db, 'document_history');
    const q = query(
      historyRef,
      where('collection', '==', inventoryCollection),
      where('modifiedBy', '==', userInfo.email),
      where('type', '==', 'update'),
      orderBy('timestamp', 'desc'),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const changes: Record<string, HistoryEntry[]> = {};
      
      snapshot.docs.forEach(doc => {
        const entry = { ...doc.data(), id: doc.id } as HistoryEntry;
        
        if (entry.deltas) {
          Object.keys(entry.deltas).forEach(field => {
            if (!changes[field]) changes[field] = [];
            changes[field].push(entry);
          });
        }
      });

      Object.keys(changes).forEach(field => {
        changes[field].sort((a, b) => {
          const timeA = a.timestamp?.toDate().getTime() || 0;
          const timeB = b.timestamp?.toDate().getTime() || 0;
          return timeB - timeA;
        });
      });

      setFieldChanges(changes);
    });

    return () => unsubscribe();
  }, [userInfo?.email, inventoryCollection, showFieldRevertDialog]);

  useEffect(() => {
    if (!userInfo?.email) {
      console.log('No user email, not setting up listener');
      return;
    }

    const historyRef = collection(db, 'document_history');
    const q = query(
      historyRef,
      where('collection', '==', inventoryCollection),
      where('modifiedBy', '==', userInfo.email),
      orderBy('timestamp', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {


      if (!snapshot.empty) {
        const lastActionData: HistoryEntry = {
          ...snapshot.docs[0].data(),
          id: snapshot.docs[0].id,
          documentId: snapshot.docs[0].data().docId
        } as HistoryEntry;
        
        
        queryClient.setQueryData<HistoryEntry>(
          ['lastUserAction', userInfo.email, inventoryCollection],
          lastActionData
        );
      } else {
        queryClient.setQueryData<HistoryEntry | null>(
          ['lastUserAction', userInfo.email, inventoryCollection],
          null
        );
      }
    }, (error) => {
      console.error('Listener error:', error);
    });

    return () => {
      unsubscribe();
    };
  }, [userInfo?.email, inventoryCollection, queryClient]);

  const { data: lastAction } = useQuery<HistoryEntry | null>({
    queryKey: ['lastUserAction', userInfo?.email, inventoryCollection],
    enabled: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!lastAction) {
      setConfirmOpen(false);
    }
  }, [lastAction]);

  const handleRevert = async () => {
    if (!lastAction) {
      setConfirmOpen(false);
      return;
    }

    try {
      switch (lastAction.type) {
        case 'delete':
          if (lastAction.fullData && (lastAction.docId || lastAction.documentId)) {
            await restoreDocument({
              id: lastAction.docId || lastAction.documentId,
              fullData: lastAction.fullData
            });
          }
          break;
        case 'create':
          if (lastAction.docId || lastAction.documentId) {
            await deleteDocument(lastAction.docId || lastAction.documentId);
          }
          break;
        case 'update':
          if ((lastAction.docId || lastAction.documentId) && lastAction.deltas) {
            await updateDocument({
              id: lastAction.docId || lastAction.documentId,
              data: lastAction.deltas,
              isRevert: true
            });
          }
          break;
      }

      const historyRef = collection(db, 'document_history');
      const q = query(
        historyRef,
        where('collection', '==', inventoryCollection),
        where('modifiedBy', '==', userInfo?.email),
        orderBy('timestamp', 'desc'),
        limit(1)
      );
      
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        setConfirmOpen(false);
      }

      queryClient.invalidateQueries({ queryKey: ['lastUserAction', userInfo?.email, inventoryCollection] });
      queryClient.invalidateQueries({ queryKey: [inventoryCollection] });

    } catch (error) {
      console.error('Error in revert:', error);
      setConfirmOpen(false); 
      throw error;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUndoLastClick = () => {
    handleClose();
    setConfirmOpen(true);
  };

  const handleFieldUndoClick = () => {
    handleClose();
    setShowFieldRevertDialog(true);
  };

  const getActionDescription = () => {
    if (!lastAction) return 'No action';
    
    switch (lastAction.type) {
      case 'create':
        return `Created vehicle ${lastAction.identifier || 'Unknown'}`;
      case 'update':
        return `Updated vehicle ${lastAction.identifier || 'Unknown'}`;
      case 'delete':
        return `Deleted vehicle ${lastAction.identifier || 'Unknown'}`;
      default:
        return 'Unknown action';
    }
  };

  const getChangeDetails = () => {
    if (!lastAction) return null;

    switch (lastAction.type) {
      case 'update':
        return (
          <Box sx={{ mt: 2, bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Changes made:
            </Typography>
            {Object.entries(lastAction.deltas || {}).map(([field, oldValue]) => {
              const newValue = lastAction.alphas?.[field];
              const fieldName = field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
              return (
                <Box key={field} sx={{ mb: 2 }}>
                  <Typography variant="body1">
                    You changed <strong>{fieldName}</strong> from{' '}
                    <Typography
                      component="span"
                      sx={{ 
                        color: 'error.main',
                        fontWeight: 'bold',
                        display: 'inline'
                      }}
                    >
                      {oldValue?.toString() || 'No value'}
                    </Typography>
                    {' '}to{' '}
                    <Typography
                      component="span"
                      sx={{ 
                        color: 'success.main',
                        fontWeight: 'bold',
                        display: 'inline'
                      }}
                    >
                      {newValue?.toString() || 'No value'}
                    </Typography>
                  </Typography>
                </Box>
              );
            })}
          </Box>
        );
      
      case 'create':
        return (
          <Box sx={{ mt: 2, bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              You created a new vehicle with these details:
            </Typography>
            {Object.entries(lastAction.fullData || {})
              .filter(([key]) => !['id', 'timestamp', 'modified_by'].includes(key))
              .map(([field, value]) => {
                const fieldName = field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                return (
                  <Box key={field} sx={{ mb: 1 }}>
                    <Typography variant="body1">
                      <strong>{fieldName}:</strong>{' '}
                      <Typography
                        component="span"
                        sx={{ 
                          color: 'success.main',
                          fontWeight: 'medium',
                          display: 'inline'
                        }}
                      >
                        {value?.toString() || 'No value'}
                      </Typography>
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        );

      case 'delete':
        return (
          <Box sx={{ mt: 2, bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              You deleted a vehicle with these details:
            </Typography>
            {Object.entries(lastAction.fullData || {})
              .filter(([key]) => ['stock_number', 'vin', 'year', 'make', 'model'].includes(key))
              .map(([field, value]) => {
                const fieldName = field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                return (
                  <Box key={field} sx={{ mb: 1 }}>
                    <Typography variant="body1">
                      <strong>{fieldName}:</strong>{' '}
                      <Typography
                        component="span"
                        sx={{ 
                          color: 'error.main',
                          fontWeight: 'medium',
                          display: 'inline'
                        }}
                      >
                        {value?.toString() || 'No value'}
                      </Typography>
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        );
    }
  };

  const handleFieldRevert = async (field: string, entries: HistoryEntry[]) => {
    try {
      for (const entry of entries) {
        if (entry.docId && entry.deltas && entry.deltas[field]) {
          await updateDocument({
            id: entry.docId,
            data: { [field]: entry.deltas[field] },
            isRevert: true
          });
        }
      }

      setShowFieldRevertDialog(false);
      queryClient.invalidateQueries({ queryKey: ['lastUserAction', userInfo?.email, inventoryCollection] });
      queryClient.invalidateQueries({ queryKey: [inventoryCollection] });
    } catch (error) {
      console.error('Error reverting field changes:', error);
      throw error;
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="warning"
        startIcon={<UndoIcon />}
        onClick={handleClick}
        disabled={!lastAction}
        sx={{ ml: 2 }}
      >
        Undo Changes
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleUndoLastClick} disabled={!lastAction}>
          <ListItemIcon>
            <HistoryIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText 
            primary="Undo Last Action" 
            secondary={lastAction ? dayjs(lastAction.timestamp?.toDate()).format('MMM D, h:mm A') : 'No actions to undo'}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleFieldUndoClick}>
          <ListItemIcon>
            <FilterListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText 
            primary="Undo by Field" 
            secondary="Revert multiple changes to specific fields"
          />
        </MenuItem>
      </Menu>

      <Dialog
        open={showFieldRevertDialog}
        onClose={() => setShowFieldRevertDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Undo Changes by Field</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {Object.entries(fieldChanges).map(([field, entries]) => (
              <Box key={field} sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="subtitle1">
                      {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {entries.length} changes • Last change: {
                        dayjs(entries[0].timestamp?.toDate()).format('MMM D, YYYY h:mm A')
                      }
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => handleFieldRevert(field, entries)}
                  >
                    Undo All {entries.length} Changes
                  </Button>
                </Stack>
              </Box>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFieldRevertDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        {lastAction && (
          <>
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="h6">Undo Last Action</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {dayjs(lastAction.timestamp?.toDate()).format('MMM D, YYYY h:mm A')}
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.secondary">
                  {remainingActions > 1 
                    ? `${remainingActions - 1} more ${remainingActions - 1 === 1 ? 'action' : 'actions'} can be undone`
                    : 'Last action that can be undone'}
                </Typography>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                {getActionDescription()}
              </Typography>
              {getChangeDetails()}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmOpen(false)}>Close</Button>
              <Button onClick={handleRevert} color="warning" variant="contained">
                Undo This Action
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}; 