// import { scan } from 'react-scan'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import { theme } from './theme'
const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement!)
import HyperDX from '@hyperdx/browser'
import "ag-grid-enterprise";
import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-069947}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Optic_Truck_Works}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Optic_Truck_Works}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Optic_Truck_Works}_need_to_be_licensed___{Optic_Truck_Works}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{22_October_2025}____[v3]_[01]_MTc2MTA4NzYwMDAwMA==0dd942c4227caac92bf45172f8866f2c")
// scan({
//   enabled: process.env.NODE_ENV === 'development',
//   log: true, // logs render info to console (default: false)
// });

if (window.location.hostname === 'portal.optictruckworks.com') {
  HyperDX.init({
    apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
    service: 'otw-portal',
    tracePropagationTargets: [/api.optictruckworks.com/i], // Set to link traces from frontend to backend requests
    consoleCapture: true, // Capture console logs (default false)
    advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
  })
} else if (window.location.hostname === 'dev-portal.optictruckworks.com') {
  HyperDX.init({
    apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
    service: 'stg-otw-portal',
    tracePropagationTargets: [/dev-api.optictruckworks.com/i], // Set to link traces from frontend to backend requests
    consoleCapture: false, // Capture console logs (default false)
    advancedNetworkCapture: false, // Capture full HTTP request/response headers and bodies (default false)
  })
}

root.render(
  
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
    ,
  </React.StrictMode>,
)
