import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Stack,
  Grid,
  Skeleton,
  useTheme,
  ButtonGroup,
  Dialog,
  CircularProgress,
} from '@mui/material'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import AgGridFirestoreCRUD from './components/AgGridInventory/AgGridInventory'
import { generateOpticStockNumber } from 'core/api/inventory/generateStockNumber'
import VehicleForm from './components/Forms/VehicleForm'
import { sanitizeNumber } from 'core/utils/inventoryUtils'
import { db } from 'core/config/firebase'
import { calculateProfit } from 'core/utils/inventoryUtils'
import { doc } from 'firebase/firestore'
import { useInventory, VehicleFilters } from 'hooks/useInventory'
import { useSnackbar } from 'contexts/snackBarContext'
import { useAuth } from 'contexts/AuthContext'
import { handleInventoryCsvUpload } from 'core/api/inventory/handleInventoryCsvUpload'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import ListIcon from '@mui/icons-material/List'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import VehicleSidebarFilter from './components/VehicleSidebarFilter'
import VehicleCard from './components/Cards/VehicleCard'
import PhotoUploadDialog from './components/Dialogs/PhotoUploadDialog'
import ManagePhotosDialog from './components/Dialogs/ManagePhotosDialog'
import SelectMainPhotoDialog from './components/Dialogs/SelectMainWebsitePhotoDialog'
import { addToWebsiteInventory } from 'core/api/inventory/showHideOnWebsiteInventory'
import { removeFromWebsiteInventory } from 'core/api/inventory/removeFromWebsiteInventory'
import { LoadingInventory } from './components/LoadingInventory'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import { useLocalStorageState } from '@toolpad/core'
import { useQueryClient } from '@tanstack/react-query'
import { UndoLastAction } from 'components/UndoLastAction'

const GRID_THEMES = [
  { name: 'Default', rowHeight: 32, fontSize: '14px' },
  // { name: 'Compact', rowHeight: 16, fontSize: '12px' },
  // { name: 'Large', rowHeight: 64, fontSize: '32px' },
]

const InventoryV3 = ({ inventoryCollection }: { inventoryCollection: string }) => {
  return (
    <InventoryContent key={inventoryCollection} inventoryCollection={inventoryCollection} />
  )
}

// Move all the existing component logic to InventoryContent
const InventoryContent = ({ inventoryCollection }: { inventoryCollection: string }) => {
  const gridRef = useRef<AgGridReact>(null)
  const { userInfo } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [selectedTheme, setSelectedTheme] = useState(GRID_THEMES[0])
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [openVehicleForm, setOpenVehicleForm] = useState(false)
  const [openManagePhotosDialog, setOpenManagePhotosDialog] = useState(false)
  const [openSelectMainPhotoDialog, setOpenSelectMainPhotoDialog] = useState(false)
  const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState(false)
  const [selectedVehicleForAssignment, setSelectedVehicleForAssignment] = useState<string | null>(null)
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false)
  const [view, setView] = useLocalStorageState<'list' | 'card'>(`${inventoryCollection}-inventoryView`, 'list')
  // const [view, setView] = useState<'list' | 'card'>('list')
  const theme = useTheme()

  // const { isOpen, toggleSidebar } = useSidebarOpen()

  // useEffect(() => {
  //   toggleSidebar()
  // }, [])

  const isMobile = useIsMobile()

  const PhotoQueryManager: React.FC<{ vehicleId: string }> = ({ vehicleId }) => {
    usePhotosForInventoryObject(inventoryCollection, vehicleId)
    return null
  }

  const initialFilters: VehicleFilters = {
    vin: '',
    make: '',
    model: '',
    stockNumber: '',
    type: '',
    size: '',
    fuel: '',
    miles: [0, 500000],
    listPrice: [0, 100000],
    askingPrice: [0, 100000],
    potentialProfit: [0, 50000],
    location: '',
    contact: '',
    addedDate: null,
    isAvailable: false,
  }

  const [filters, setFilters] = useState<VehicleFilters>(initialFilters)

  const { vehicles, addDocument, updateDocument, isLoading, deleteDocument, queryClient, refetchVehicles } =
    useInventory(inventoryCollection)


    
  // Get the filtered vehicles only for card view
  const visibleVehicles = useMemo(() => {
    // If we're in list view, return all vehicles
    if (view === 'list' && !isMobile) {
      return vehicles?.slice(0, 1000)
    }

    // If we're in card view, return filtered vehicles
    if (!vehicles) return []

    const filtered = vehicles.filter(vehicle => {
      const meetsVinFilter = !filters.vin || (vehicle.vin || '').toLowerCase().includes(filters.vin.toLowerCase())

      const meetsStockFilter =
        !filters.stockNumber ||
        (vehicle.stock_number || '').toLowerCase().includes(filters.stockNumber.toLowerCase())
      const meetsTypeFilter = !filters.type || vehicle.type === filters.type
      const meetsSizeFilter = !filters.size || vehicle.size === filters.size
      const meetsFuelFilter = !filters.fuel || vehicle.fuel === filters.fuel
      const meetsMakeFilter = !filters.make || (vehicle.make || '').toLowerCase().includes(filters.make.toLowerCase())
      const meetsModelFilter = !filters.model || (vehicle.model || '').toLowerCase().includes(filters.model.toLowerCase())

      const meetsMilesFilter = Number(vehicle.miles) >= filters.miles[0] && Number(vehicle.miles) <= filters.miles[1]

      const meetsListPriceFilter =
        Number(vehicle.optic_list_price) >= filters.listPrice[0] &&
        Number(vehicle.optic_list_price) <= filters.listPrice[1]

      const meetsAskingPriceFilter =
        Number(vehicle.seller_asking_price) >= filters.askingPrice[0] &&
        Number(vehicle.seller_asking_price) <= filters.askingPrice[1]

      const meetsLocationFilter =
        !filters.location || (vehicle.location || '').toLowerCase().includes(filters.location.toLowerCase())

      const meetsAvailabilityFilter = !filters.isAvailable || vehicle.status === 'Available'

      return (
        meetsVinFilter &&
        meetsStockFilter &&
        meetsTypeFilter &&
        meetsSizeFilter &&
        meetsFuelFilter &&
        meetsMilesFilter &&
        meetsListPriceFilter &&
        meetsAskingPriceFilter &&
        meetsLocationFilter &&
        meetsAvailabilityFilter &&
        meetsMakeFilter &&
        meetsModelFilter
      )
    })

    return filtered.slice(0, 1000)
  }, [vehicles, filters, view])

  const handleUpdateFilters = useCallback((newFilters: VehicleFilters) => {
    setFilters(newFilters)
  }, [])

  const handleClearFilters = useCallback(() => {
    setFilters(initialFilters)
    // Set loading states
    setIsCardViewLoading(true)
    setIsInitialLoad(true)

    // Refetch and reset loading states after a delay
    refetchVehicles().then(() => {
      setTimeout(() => {
        setIsCardViewLoading(false)
        setIsInitialLoad(false)
      }, 500)
    })
  }, [refetchVehicles])

  const handleAddVehicle = async (data: { [key: string]: string | number | boolean }) => {
    const newValues = Object.fromEntries(Object.entries(data).filter(([, value]) => value !== undefined))
    newValues.date_added = new Date().toISOString()
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      newValues.profit = calculateProfit(String(newValues.seller_asking_price), String(newValues.optic_list_price))
    }
    addDocument(newValues)
    setOpenVehicleForm(false)
  }

  const handleEditVehicle = async (data: { [key: string]: string | number | boolean }) => {
    if (!selectedVehicle) return
    const sanitizedData = { ...data }
    sanitizedData.miles = sanitizeNumber(sanitizedData.miles)
    sanitizedData.optic_list_price = sanitizeNumber(sanitizedData.optic_list_price)
    sanitizedData.seller_asking_price = sanitizeNumber(sanitizedData.seller_asking_price)

    const newValues = Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined))
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      newValues.profit = calculateProfit(String(newValues.seller_asking_price), String(newValues.optic_list_price))
    }

    try {
      updateDocument({
        id: selectedVehicle.id,
        data: newValues,
      })
      setOpenVehicleForm(false)
    } catch (error) {
      showSnackbar('Error updating vehicle', 'error')
      console.error('Error updating vehicle:', error)
    }
  }

  const handleDownloadCSVTemplate = () => {
    window.open('/inventory_template.csv')
  }

  const handleDeleteVehicle = (vehicleId: string) => {
    deleteDocument(vehicleId)
  }

  const handleManagePhotosClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle as Vehicle)
    setOpenManagePhotosDialog(true)
  }

  const handleShowHideOnWebsiteClick = async (vehicle: Vehicle) => {
    if (vehicle.show_on_website !== true) {
      setOpenSelectMainPhotoDialog(true)
    }
    if (vehicle.show_on_website) {
      await removeFromWebsiteInventory(vehicle.id)
    } else {
      await addToWebsiteInventory(vehicle.id)
    }
    await updateDocument({
      id: vehicle.id,
      data: { show_on_website: !vehicle.show_on_website },
    })
  }
  const uType = userInfo?.user_type

  // useEffect(() => {
  //   if (vehicles) {
  //     setFilteredVehicles(vehicles.slice(0, 10))
  //   }
  // }, [handleFilterChange])

  // Add this state to track if we're switching views
  const [isViewChanging, setIsViewChanging] = useState(false)

  // Add this loading state specifically for card view
  const [isCardViewLoading, setIsCardViewLoading] = useState(false)

  // Add this near the other state declarations
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  // Update the useEffect that handles initial load
  useEffect(() => {
    if (view === 'card') {
      setIsCardViewLoading(true)
      // Only show loading state briefly if we have data
      const timer = setTimeout(() => {
        if (vehicles) {
          setIsCardViewLoading(false)
          setIsInitialLoad(false)
        }
      }, 500)
      return () => clearTimeout(timer)
    } else {
      // If not in card view, make sure loading states are false
      setIsCardViewLoading(false)
      setIsInitialLoad(false)
    }
  }, [view, vehicles]) // Add vehicles to dependency array

  // Modify the view toggle handler
  const handleViewChange = (_event: any, newView: 'list' | 'card' | null) => {
    if (newView) {
      setIsViewChanging(true)
      setView(newView)
      if (newView === 'card') {
        setIsCardViewLoading(true)
      }
      // Only hide loading state if we have data
      if (vehicles) {
        setTimeout(() => {
          setIsViewChanging(false)
          setIsCardViewLoading(false)
        }, 100)
      }
    }
  }

  const [isLoadingVehicleForm, setIsLoadingVehicleForm] = useState(false)

  // Add this state near other state declarations
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true)

  // Add this useEffect at the top level
  useEffect(() => {
    // Show loading state on mount
    setIsInitialPageLoad(true)

    // Hide loading state after a delay
    const timer = setTimeout(() => {
      setIsInitialPageLoad(false)
    }, 1000) // Adjust timing as needed

    return () => clearTimeout(timer)
  }, []) // Empty dependency array means this runs once on mount

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
      }}
    >
      {isInitialPageLoad ? (
        <LoadingInventory />
      ) : (
        <>
          {/* Header section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
            }}
          >
            {/* Title Section */}
            <Typography variant='h6' sx={{ color: '#666666' }}>
              {inventoryCollection === 'master_inventory' ? 'Optic Inventory Management 2.0' : 'Shield Inventory Management 2.0'}
            </Typography>

            {/* Controls Section */}
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems={isMobile ? 'stretch' : 'center'}>
              {/* Primary Actions */}
              <Stack direction='row' spacing={2}>
                <ButtonGroup variant='contained' sx={{ bgcolor: 'background.paper' }}>
                  <Button
                    variant='contained'
                    startIcon={<LocalShippingIcon />}
                    onClick={() => {
                      setSelectedVehicle(null)
                      setOpenVehicleForm(true)
                    }}
                    sx={{
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      bgcolor: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: theme.palette.text.secondary,
                      },
                    }}
                  >
                    Add Truck
                  </Button>
                  {isMobile && (
                    <VehicleSidebarFilter
                      filters={filters}
                      onFilterChange={handleUpdateFilters}
                      onFilterClear={handleClearFilters}
                      isAdmin={uType === 'admin'}
                    />
                  )}
                  {!isMobile && (
                    <Button
                      sx={{
                        textTransform: 'none',
                        whiteSpace: 'nowrap',
                        bgcolor: '#808080',
                        '&:hover': { bgcolor: '#666666' },
                      }}
                      onClick={handleDownloadCSVTemplate}
                    >
                      Download CSV Template
                    </Button>
                  )}
                  {!isMobile && (
                    <Button
                      component='label'
                      sx={{
                        textTransform: 'none',
                        whiteSpace: 'nowrap',
                        bgcolor: '#808080',
                        '&:hover': { bgcolor: '#666666' },
                      }}
                    >
                      Upload CSV
                      <input
                        type='file'
                        hidden
                        onChange={event => handleInventoryCsvUpload(event, inventoryCollection)}
                      />
                    </Button>
                  )}
                </ButtonGroup>

                <UndoLastAction inventoryCollection={inventoryCollection} />

                {!isMobile && (
                  <ButtonGroup>
                    <ToggleButtonGroup
                      value={view}
                      exclusive
                      onChange={handleViewChange}
                      aria-label='view mode'
                      size='small'
                    >
                      <ToggleButton value='list' aria-label='list view'>
                        <ListIcon />
                      </ToggleButton>
                      <ToggleButton value='card' aria-label='card view'>
                        <SpaceDashboardIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonGroup>
                )}
              </Stack>
            </Stack>
          </Box>

          {isLoading || !vehicles ? (
            <LoadingInventory />
          ) : isMobile || view === 'card' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexGrow: 1,
                overflow: 'hidden',
                height: 'calc(100% - 80px)',
                gap: 2,
              }}
            >
              {/* Filter sidebar */}
              <Box
                sx={{
                  width: isMobile ? '100%' : '300px',
                  flexShrink: 0,
                  position: 'sticky',
                  top: 24,
                  alignSelf: 'flex-start',
                }}
              >
                <VehicleSidebarFilter
                  filters={filters}
                  onFilterChange={handleUpdateFilters}
                  onFilterClear={handleClearFilters}
                  isAdmin={uType === 'admin'}
                />
              </Box>

              {/* Grid container with loading state */}
              <Box sx={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  {isCardViewLoading || isLoading || (isInitialLoad && view === 'card' && !vehicles)
                    ? Array.from({ length: 12 }).map((_, index) => (
                        <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Box sx={{ height: '300px', bgcolor: 'background.paper', p: 2 }}>
                            <Skeleton variant='rectangular' width='100%' height={160} />
                            <Skeleton variant='text' width='60%' />
                            <Skeleton variant='text' width='40%' />
                          </Box>
                        </Grid>
                      ))
                    : visibleVehicles?.map(vehicle => (
                        <Grid item key={vehicle.id} xs={12} sm={6} md={6} lg={4} xl={3}>
                          <VehicleCard
                            collectionName={inventoryCollection}
                            vehicle={vehicle}
                            onDeleteClick={() => handleDeleteVehicle(vehicle.id)}
                            onManagePhotosClick={() => handleManagePhotosClick(vehicle)}
                            onShowHideOnWebsiteClick={() => handleShowHideOnWebsiteClick(vehicle)}
                            isAdmin={uType === 'admin'}
                            onAssignUserClick={() => {
                              setSelectedVehicleForAssignment(vehicle.id)
                              setAssignUserDialogOpen(true)
                            }}
                            assignedUserName={vehicle.assigned_user?.email}
                            vehicles={vehicles}
                          />
                        </Grid>
                      ))}
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box sx={{ position: 'relative', flexGrow: 1 }}>
              <AgGridFirestoreCRUD
                gridRef={gridRef}
                gridTheme={selectedTheme}
                rows={vehicles || []} // Use unfiltered vehicles for grid view
                isLoading={isViewChanging}
              />
            </Box>
          )}
          <Dialog open={isLoadingVehicleForm} sx={{ backgroundColor: 'transparent' }}>
            <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          </Dialog>

          <VehicleForm
            open={openVehicleForm}
            vehicle={selectedVehicle}
            onClose={() => {
              setOpenVehicleForm(false)
              setIsLoadingVehicleForm(false)
            }}
            onSubmit={selectedVehicle ? handleEditVehicle : handleAddVehicle}
            generateStockNumber={() =>
              generateOpticStockNumber(
                (vehicles as Vehicle[])?.map(vehicle => vehicle.stock_number) || [],
                userInfo?.first_name || '',
                userInfo?.last_name || '',
              )
            }
          />
          {selectedVehicle && (
            <>
              <PhotoUploadDialog
                open={openPhotoUploadDialog}
                onClose={() => setOpenPhotoUploadDialog(false)}
                vehicle={selectedVehicle}
              />
              <ManagePhotosDialog
                collectionName={inventoryCollection}
                open={openManagePhotosDialog}
                onClose={() => setOpenManagePhotosDialog(false)}
                vehicle={selectedVehicle}
              />
              <SelectMainPhotoDialog
                collectionName={inventoryCollection}
                open={openSelectMainPhotoDialog}
                onClose={() => setOpenSelectMainPhotoDialog(false)}
                vehicle={selectedVehicle}
              />
            </>
          )}
          {/* Render PhotoQueryManager for each vehicle */}
          {/* {visibleVehicles?.map(vehicle => <PhotoQueryManager key={vehicle.id} vehicleId={vehicle.id} />)} */}
        </>
      )}
    </Box>
  )
}

export default InventoryV3
