import { ColumnPinnedType } from 'ag-grid-community'

export const COLUMN_TEMPLATES = [
  {
    id: 'default',
    name: 'Default View',
    description: 'Shows all columns in standard order',
    createdBy: 'System',
    columnState: [
      {
        colId: "vehicleIcon",
        width: 70,
        hide: false,
        pinned: null as ColumnPinnedType,
        flex: 1
      },
      {
        colId: "ag-Grid-ControlsColumn",
        width: 50,
        hide: false,
        pinned: "left" as ColumnPinnedType,
        flex: null
      },
      {
        colId: "type",
        width: 130,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "stock_number",
        width: 188,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "who",
        width: 162,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "condition",
        width: 161,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "year",
        width: 128,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "make",
        width: 149,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "model",
        width: 155,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "size",
        width: 173,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "miles",
        width: 133,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "seller_asking_price",
        width: 217,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "optic_list_price",
        width: 195,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "profit",
        width: 137,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "vin",
        width: 217,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "link",
        width: 126,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "photos",
        width: 80,
        hide: false,
        pinned: null,
        flex: 1
      },
      {
        colId: "id",
        width: 200,
        hide: true,
        pinned: null,
        flex: 1
      },
      {
        colId: "date_added",
        width: 173,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "fuel",
        width: 200,
        hide: true,
        pinned: null,
        flex: 1
      },
      {
        colId: "location",
        width: 154,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "cameras",
        width: 100,
        hide: true,
        pinned: null,
        flex: 1
      },
      {
        colId: "sold_date",
        width: 160,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "truck_notes",
        width: 175,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "phone_number",
        width: 192,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "email",
        width: 166,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "follow_up_notes",
        width: 200,
        hide: true,
        pinned: null,
        flex: 1
      },
      {
        colId: "entered_by",
        width: 178,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "payoff_amount",
        width: 194,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "repair_cost",
        width: 172,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "shipping_cost",
        width: 185,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "show_on_website",
        width: 172,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "status",
        width: 169,
        hide: false,
        pinned: null,
        flex: null
      }
    ]
  },
  {
    id: 'financial',
    name: 'Financial View',
    description: 'Focus on prices and profit',
    createdBy: 'System',
    columnState: [
      {
        colId: "vehicleIcon",
        width: 70,
        hide: false,
        pinned: null as ColumnPinnedType,
        flex: 1
      },
      {
        colId: "ag-Grid-ControlsColumn",
        width: 50,
        hide: false,
        pinned: "left" as ColumnPinnedType,
        flex: null
      },
      {
        colId: "stock_number",
        width: 188,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "optic_list_price",
        width: 195,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "seller_asking_price",
        width: 217,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "profit",
        width: 137,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "repair_cost",
        width: 172,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "shipping_cost",
        width: 185,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "payoff_amount",
        width: 194,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "sold_date",
        width: 160,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "status",
        width: 169,
        hide: false,
        pinned: null,
        flex: null
      }
    ]
  },
  {
    id: 'vehicle-details',
    name: 'Vehicle Details',
    description: 'Focus on vehicle specifications',
    createdBy: 'System',
    columnState: [
      {
        colId: "vehicleIcon",
        width: 70,
        hide: false,
        pinned: null as ColumnPinnedType,
        flex: 1
      },
      {
        colId: "ag-Grid-ControlsColumn",
        width: 50,
        hide: false,
        pinned: "left" as ColumnPinnedType,
        flex: null
      },
      {
        colId: "year",
        width: 128,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "make",
        width: 149,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "model",
        width: 155,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "size",
        width: 173,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "miles",
        width: 133,
        hide: false,
        pinned: null,
        aggFunc: "avg",
        flex: null
      },
      {
        colId: "fuel",
        width: 200,
        hide: false,
        pinned: null,
        flex: 1
      },
      {
        colId: "vin",
        width: 217,
        hide: false,
        pinned: null,
        flex: null
      },
      {
        colId: "type",
        width: 130,
        hide: false,
        pinned: null,
        flex: null
      }
    ]
  }
]