import React, { useRef, useEffect, useMemo } from 'react'
import { Box, TextField, InputAdornment, IconButton, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { GridApi } from 'ag-grid-enterprise'
import { debounce } from 'lodash'

interface QuickFilterInputProps {
  value: string
  onChange: (value: string) => void
  gridApi: React.MutableRefObject<GridApi | null>
}

export const QuickFilterInput: React.FC<QuickFilterInputProps> = ({ value, onChange, gridApi }) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)

  const debouncedFilter = useMemo(
    () => debounce((value: string) => {
      if (gridApi.current) {
        gridApi.current.setFilterModel({
          $: {
            filterType: 'text',
            filter: value
          }
        })
      }
    }, 150),
    [gridApi]
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    onChange(newValue)
    debouncedFilter(newValue)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      onChange('')
      debouncedFilter('')
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    const handleGlobalKeyDown = (e: KeyboardEvent) => {
      if (e.key === '/' && document.activeElement?.tagName !== 'INPUT') {
        e.preventDefault()
        inputRef.current?.focus()
      }
    }

    document.addEventListener('keydown', handleGlobalKeyDown)
    return () => document.removeEventListener('keydown', handleGlobalKeyDown)
  }, [])

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      size="small"
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Quick Filter... (Press '/' to focus)"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'text.secondary', fontSize: '1.2rem' }} />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                onChange('')
                debouncedFilter('')
                inputRef.current?.focus()
              }}
              edge="end"
              sx={{ p: 0.5 }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          height: '36px',
          backgroundColor: 'transparent',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'divider'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main',
            borderWidth: '1px'
          }
        }
      }}
      sx={{
        '& .MuiInputBase-root': {
          fontSize: '0.875rem',
        }
      }}
    />
  )
}

export default React.memo(QuickFilterInput) 