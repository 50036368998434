import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Alert,
  Divider,
  Chip,
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import SaveIcon from '@mui/icons-material/Save'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

interface SaveTemplateDialogProps {
  open: boolean
  onClose: () => void
  onSave: (name: string, description: string, setAsDefault?: boolean) => void
  onUpdate?: (name: string, description: string, setAsDefault?: boolean) => void
  isUpdate?: boolean
  currentTemplate?: {
    name: string
    description?: string
  }
  visibleColumns?: string[]
  baseTemplate?: string
  isCurrentDefault?: boolean
}

export const SaveTemplateDialog: React.FC<SaveTemplateDialogProps> = ({
  open,
  onClose,
  onSave,
  onUpdate,
  isUpdate = false,
  currentTemplate,
  visibleColumns = [],
  baseTemplate = 'Default',
  isCurrentDefault = false,
}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [setAsDefault, setSetAsDefault] = useState(false)

  useEffect(() => {
    if (currentTemplate && isUpdate) {
      setName(currentTemplate.name)
      setDescription(currentTemplate.description || '')
      setSetAsDefault(isCurrentDefault)
    } else {
      setName(`${baseTemplate} - Custom`)
      setDescription('')
      setSetAsDefault(false)
    }
  }, [currentTemplate, isUpdate, open, baseTemplate, isCurrentDefault])

  const handleSave = () => {
    if (isUpdate && onUpdate) {
      onUpdate(name, description, setAsDefault)
    } else {
      onSave(name, description, setAsDefault)
    }
    setName('')
    setDescription('')
    setSetAsDefault(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        {isUpdate ? 'Update Template Layout' : 'Save Current Layout as Template'}
      </DialogTitle>
      
      {!isUpdate ? (
        <Alert severity="info" sx={{ mx: 3, mt: 1 }}>
          You're creating a new template based on your current column selection
        </Alert>
      ) : (
        <Alert severity="info" sx={{ mx: 3, mt: 1 }}>
          You're updating this template with your current column selection
        </Alert>
      )}

      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {!isUpdate && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ContentCopyIcon color="action" fontSize="small" />
              <Typography variant="body2" color="text.secondary">
                Based on: <strong>{baseTemplate}</strong>
              </Typography>
            </Box>
          )}

          <TextField
            label="Template Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
            helperText="Give your template a descriptive name"
          />
          
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={2}
            helperText="Optional: Add notes about this template's purpose"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={setAsDefault}
                onChange={(e) => setSetAsDefault(e.target.checked)}
                icon={<StarBorderIcon />}
                checkedIcon={<StarIcon sx={{ color: 'warning.main' }} />}
              />
            }
            label="Set as default template"
          />

          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ViewColumnIcon fontSize="small" />
              Visible Columns ({visibleColumns.length})
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {visibleColumns.map((column) => (
                <Chip 
                  key={column} 
                  label={column}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <Divider sx={{ my: 1 }} />
      
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave} 
          disabled={!name} 
          variant="contained"
          startIcon={<SaveIcon />}
        >
          {isUpdate ? 'Save Changes' : 'Save as New Template'}
        </Button>
      </DialogActions>
    </Dialog>
  )
} 