import { useState } from 'react'
import { Box, IconButton, Select, MenuItem, Typography, CircularProgress } from '@mui/material'
import { AddPhotoAlternate } from '@mui/icons-material'
import { Delete } from '@mui/icons-material'
import React from 'react'
import { useSnackbar } from 'contexts/snackBarContext'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'

export const VehiclePhotosRenderer: React.FC<any> = props => {
  const [uploadingFiles, setUploadingFiles] = useState<{ 
    file: File; 
    previewBlobUrl: string;
    loading: boolean 
  }[]>([])
  const { showSnackbar } = useSnackbar()
  const vehicle = props.data

  const { 
    data: photos, 
    deletePhotos,
    uploadPhotos,
    updatePosition,
    isDeleting,
    isUploading,
    isUpdating,
    isLoading
  } = usePhotosForInventoryObject('master_inventory', vehicle.id, {
    enabled: props.node.expanded
  })

  if (isLoading) {
    return (
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '200px'
      }}>
        <CircularProgress />
      </Box>
    )
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const filesArray = Array.from(e.target.files)
    const filesWithPreview = filesArray.map(file => ({
      file,
      previewBlobUrl: URL.createObjectURL(file),
      loading: true
    }))

    setUploadingFiles(filesWithPreview)
    uploadPhotos(
      filesWithPreview,
      {
        onSuccess: () => {
          showSnackbar('Photos uploaded successfully', 'success')
          setUploadingFiles([])
        },
        onError: (error: Error) => {
          showSnackbar('Error uploading photos', 'error')
          console.error('Error uploading photos:', error)
          setUploadingFiles([])
        }
      }
    )
  }

  return (
    <Box sx={{ p: 2, bgcolor: 'primary.light' }}>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        {/* Add Photo Card */}
        <Box
          component='label'
          sx={{
            width: 200,
            height: 200,
            border: '2px dashed',
            borderColor: '#808080',
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 0.2s',
            '&:hover': {
              borderColor: '#a6a6a6',
              bgcolor: '#333333',
            },
          }}
        >
          <input type='file' hidden multiple accept='image/*' onChange={handleFileUpload} />
          <AddPhotoAlternate sx={{ fontSize: 40, color: '#808080', mb: 1 }} />
          <Typography variant='body1' color='#808080'>
            Add Photos
          </Typography>
        </Box>

        {/* Loading Photo Cards */}
        {uploadingFiles.map((file, index) => (
          <Box
            key={`loading-${index}`}
            sx={{
              width: 200,
              height: 200,
              position: 'relative',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0, 0, 0, 0.1)',
            }}
          >
            <CircularProgress />
            <img
              src={file.previewBlobUrl}
              alt="Uploading..."
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                opacity: 0.5,
                position: 'absolute',
              }}
            />
          </Box>
        ))}

        {/* Photo Cards */}
        {Array.isArray(photos) &&
          photos.map((photo: VehiclePhoto) => (
            <Box
              key={photo.url}
              sx={{
                width: 200,
                height: 200,
                position: 'relative',
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: 3,
                '&:hover .photo-overlay': {
                  opacity: 1,
                },
              }}
            >
              <img
                src={photo.url}
                alt={photo.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              {/* Overlay with actions */}
              <Box
                className='photo-overlay'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 1,
                  opacity: 0,
                  transition: 'opacity 0.2s',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Select
                    value={photo.position || ''}
                    onChange={e =>
                      updatePosition({
                        name: photo.name,
                        position: e.target.value as PhotoPosition,
                      })
                    }
                    sx={{
                      color: 'white',
                      '.MuiSelect-icon': { color: 'white' },
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                    }}
                    size='small'
                  >
                    {['main', 'front', 'back', 'left', 'right', 'interior'].map(pos => (
                      <MenuItem key={pos} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton onClick={() => deletePhotos([photo])} sx={{ color: '#ff4d4d' }}>
                    <Delete />
                  </IconButton>
                </Box>
                <Typography variant='caption' sx={{ color: 'white', textAlign: 'center' }}>
                  {photo.position || 'No position set'}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  )
}
