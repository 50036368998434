export const ZOOM_LIMITS = {
    FONT: {
      MIN: 8, 
      MAX: 20,
      DEFAULT: 14
    },
    ROW: {
      MIN: 24, 
      MAX: 100,
      DEFAULT: 24
    }
  } as const
  
  export const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    floatingFilter: true,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    flex: 1,
    minWidth: 100,
    editable: true,
    singleClickEdit: true,
    enableCellChangeFlash: true,
  }
  
  export const statusBar = {
    statusPanels: [
      { 
        statusPanel: 'agTotalAndFilteredRowCountComponent', 
        align: 'left' 
      },
      { 
        statusPanel: 'agSelectedRowCountComponent', 
        align: 'left' 
      },
      {
        statusPanel: 'agAggregationComponent',
        align: 'right'
      }
  ]
}
